import React from "react";
import { BellIcon } from "@heroicons/react/solid";

function MaintainenceNotice() {
  return (
    <div className="rounded-md bg-yellow-50 p-4 mb-12">
      <div className="flex">
        <div className="flex-shrink-0">
          <BellIcon className="h-6 w-6" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            Under Maintainence
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              We noticed that the service has been unstable lately. We are
              taking steps to fix this, expect us to be back up in the next few
              hours. Thank you for your patience. Please contact us on Discord
              with any questions.
            </p>
            <p className="pt-4">
              Translated to Turkish using Google (Google tarafından çevrildi):
              Son zamanlarda hizmetin kararsız olduğunu fark ettik. Bunu
              düzeltmek için adımlar atıyoruz, önümüzdeki birkaç saat içinde
              geri dönmemizi bekliyoruz. Sabrınız için teşekkürler. Sorularınız
              için lütfen Discord üzerinden bizimle iletişime geçin.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaintainenceNotice;
