import React from "react";

function SearchIcon(props: any) {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="flex-none text-slate-300"
      aria-hidden="true"
      {...props}
    >
      <path d="m19 19-3.5-3.5"></path>
      <circle cx="11" cy="11" r="6"></circle>
    </svg>
  );
}

export default SearchIcon;
