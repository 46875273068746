import { useCallback } from "react";
import { useRouter } from "next/router";
import { UserCircleIcon, SwitchHorizontalIcon } from "@heroicons/react/solid";

import { rejectNotHandledSwitchStatement } from "utils";
import * as paths from "utils/paths";

import useWalletsStore from "stores/useWalletsStore";
import useSafeClearApolloStore from "hooks/data/useSafeClearApolloStore";
import { clear } from "console";

enum DropdownOptionIdentifier {
  PROFILE = "PROFILE",
  DISCONNECT = "DISCONNECT",
}

type dropdownOption = {
  id: DropdownOptionIdentifier;
  displayName: string;
  icon: any;
};

export const dropdownOptions: dropdownOption[] = [
  {
    id: DropdownOptionIdentifier.PROFILE,
    displayName: "Profile",
    icon: UserCircleIcon,
  },
  {
    id: DropdownOptionIdentifier.DISCONNECT,
    displayName: "Disconnect",
    icon: SwitchHorizontalIcon,
  },
];

function useHandleWalletMenuCallback(accountAddress: string) {
  const clearApolloStore = useSafeClearApolloStore();
  const router = useRouter();
  const disconnectStarknetWallet = useWalletsStore(
    (state) => state.disconnectStarknetWallet
  );

  return useCallback(
    async (identifier: DropdownOptionIdentifier) => {
      switch (identifier) {
        case DropdownOptionIdentifier.PROFILE: {
          const toRoute = paths.profile(accountAddress);
          const isCurrentPath =
            paths.removeQueryParams(router.asPath) === toRoute;
          if (!isCurrentPath) {
            router.push(paths.profile(accountAddress));
            await clearApolloStore();
          } else {
            router.push(router.asPath);
          }
          break;
        }
        case DropdownOptionIdentifier.DISCONNECT: {
          disconnectStarknetWallet();
          break;
        }
        default: {
          // should not be possible, typescript should error if not all cases are handled
          rejectNotHandledSwitchStatement(identifier);
        }
      }
    },
    [accountAddress, router, disconnectStarknetWallet, clearApolloStore]
  );
}

export default useHandleWalletMenuCallback;
