import React, { Fragment } from "react";

import { Popover, Transition } from "@headlessui/react";

import { truncateAddress } from "utils";
import { ProfileWithMetadata } from "utils/populate/profile";
import { classNames } from "utils/styles";

import FallbackGradientImage from "components/modules/FallbackGradientImage";
import useHandleWalletMenuCallback, {
  dropdownOptions,
} from "./useHandleWalletMenuCallback";

function WalletMenu({
  accountAddress,
  profileWithMetdata,
}: {
  accountAddress: string;
  profileWithMetdata: ProfileWithMetadata | null;
}) {
  const handleWalletMenu = useHandleWalletMenuCallback(accountAddress);

  return (
    <Popover className="relative w-44">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-gray-900" : "text-gray-500",
              "group bg-transparent rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none"
            )}
          >
            <div className="rounded-full flex items-center cursor-pointer hover:bg-gray-200 px-3 py-2">
              <div className="w-10">
                <FallbackGradientImage
                  gradientHash={accountAddress}
                  imageUrl={profileWithMetdata?.displayProfileImageUrl || null}
                  className="h-8 w-8 rounded-full"
                />
              </div>
              <div className="ml-1 text-sm font-semibold whitespace-nowrap text-black font-sans">
                {profileWithMetdata?.displayIdentifierRawShort ||
                  truncateAddress(accountAddress)}
              </div>
            </div>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-6 bg-white px-5 py-6">
                  {dropdownOptions.map((item) => (
                    <div
                      key={item.id}
                      className="cursor-pointer -m-3 p-3 flex items-start rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                      onClick={() => {
                        handleWalletMenu(item.id);
                        close();
                      }}
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-gray-600"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {item.displayName}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default WalletMenu;
