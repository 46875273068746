import { useRouter } from "next/router";

import { truncateIdentifier, truncateAddress } from "utils";
import { AssetWithMetadata, isAssetWithMetadata } from "utils/populate/asset";
import {
  ContractWithMetadata,
  isContractWithMetadata,
} from "utils/populate/contract";
import {
  ProfileWithMetadata,
  isProfileWithMetadata,
} from "utils/populate/profile";

import FallbackGradientImage from "components/modules/FallbackGradientImage";
import { Combobox } from "@headlessui/react";

function Preview({
  activeOption,
  closeModal,
}: {
  activeOption: any;
  closeModal: Function;
}) {
  if (!activeOption) return <></>;

  if (isAssetWithMetadata(activeOption)) {
    const asset = activeOption as AssetWithMetadata;

    return (
      <PreviewInternal
        gradientHash={asset.id}
        displayUrl={asset.imageUrl}
        name={asset.name || null}
        description={asset.description || null}
        metadatas={[
          {
            header: "Collection",
            value: truncateAddress(asset.contract_address),
          },
          {
            header: "Token Number",
            value: truncateIdentifier(asset.token_id),
          },
        ]}
        toRouteUrl={asset.routeUrl}
        closeModal={closeModal}
      />
    );
  }

  if (isContractWithMetadata(activeOption)) {
    const contract = activeOption as ContractWithMetadata;
    const metadatas = [
      {
        header: "Contract Address",
        value: contract.displayContractAddressShort,
      },
    ];
    if (contract.displayNameShort) {
      metadatas.push({
        header: "Name",
        value: contract.displayNameShort,
      });
    }
    if (contract.symbol) {
      metadatas.push({
        header: "Symbol",
        value: truncateIdentifier(contract.symbol),
      });
    }

    return (
      <PreviewInternal
        gradientHash={contract.contract_address}
        displayUrl={null}
        name={contract.name || null}
        description={null}
        metadatas={metadatas}
        toRouteUrl={contract.routeUrl}
        closeModal={closeModal}
      />
    );
  }

  if (isProfileWithMetadata(activeOption)) {
    const profile = activeOption as ProfileWithMetadata;
    return (
      <PreviewInternal
        gradientHash={profile.account_address}
        displayUrl={profile.displayProfileImageUrl}
        name={profile.displayIdentifierShort}
        description={profile.biography || null}
        metadatas={[]}
        toRouteUrl={profile.routeUrl}
        closeModal={closeModal}
      />
    );
  }

  return <></>;
}

type Metadata = {
  header: string;
  value: string;
};

function PreviewInternal({
  gradientHash,
  displayUrl,
  name,
  description,
  metadatas,
  toRouteUrl,
  closeModal,
}: {
  gradientHash: string;
  displayUrl: string | null;
  name: string | null;
  description: string | null;
  metadatas: Metadata[];
  toRouteUrl: string | null;
  closeModal: Function;
}) {
  const router = useRouter();

  return (
    <Combobox.Options
      static
      hold
      className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex"
    >
      <div className="flex-none p-6 text-center">
        <FallbackGradientImage
          className="mx-auto h-16 w-16 rounded-full"
          gradientHash={gradientHash}
          imageUrl={displayUrl}
        />
        {name && <h2 className="mt-3 font-semibold text-gray-900">{name}</h2>}
        {description && (
          <p className="text-sm leading-6 text-gray-500">{description}</p>
        )}
      </div>
      <div className="flex flex-auto flex-col justify-between p-6">
        <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
          {metadatas.map((metadata) => (
            <>
              <dt className="col-end-1 font-semibold text-gray-900">
                {metadata.header}
              </dt>
              <dd>{metadata.value}</dd>
            </>
          ))}
        </dl>
        {toRouteUrl && (
          <button
            type="button"
            className="mt-6 w-full rounded-md border border-transparent bg-slate-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-slate-900 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
            onClick={() => {
              closeModal();
              router.push(toRouteUrl);
            }}
          >
            See details
          </button>
        )}
      </div>
    </Combobox.Options>
  );
}

export default Preview;
