import { Combobox } from "@headlessui/react";

import { AssetWithMetadata, isAssetWithMetadata } from "utils/populate/asset";
import {
  ContractWithMetadata,
  isContractWithMetadata,
} from "utils/populate/contract";
import {
  ProfileWithMetadata,
  isProfileWithMetadata,
} from "utils/populate/profile";
import CategoryList from "./CategoryList";

type entitiesTypeLists =
  | AssetWithMetadata[]
  | ContractWithMetadata[]
  | ProfileWithMetadata[];
type entitiesType =
  | AssetWithMetadata
  | ContractWithMetadata
  | ProfileWithMetadata;

function Category({
  header,
  entities,
}: {
  header: string;
  entities: entitiesTypeLists;
}) {
  return (
    <>
      <h2 className="mt-2 mb-4 text-xs font-semibold text-gray-500">
        {header}
      </h2>
      <Combobox.Options static hold className="-mx-2 text-sm text-gray-700">
        {entities.map((entity: entitiesType) => {
          let id = "";
          let value = entity;
          let displayUrl: string | null = null;
          let name = "";
          let routeUrl = "";

          if (isAssetWithMetadata(entity)) {
            entity = entity as AssetWithMetadata;
            id = entity.id;
            displayUrl = entity.imageUrl || null;
            name = entity.name || "";
          } else if (isContractWithMetadata(entity)) {
            entity = entity as ContractWithMetadata;
            id = entity.contract_address;
            name = entity.name || "";
          } else if (isProfileWithMetadata(entity)) {
            entity = entity as ProfileWithMetadata;
            id = entity.account_address;
            name = entity.displayIdentifierShort || "";
            displayUrl = entity.displayProfileImageUrl;
          }

          return (
            <CategoryList
              key={id}
              id={id}
              value={value}
              displayUrl={displayUrl}
              name={name}
            />
          );
        })}
      </Combobox.Options>
    </>
  );
}

export default Category;
