import MenuItem from "./MenuItem";

import { NAVIGATION } from "components/common/Layout/Layout.utils";

function MenuItems() {
  return <>
    {NAVIGATION.map((item) => {
      if (!item.displayInHeader) {
        return null
      }

      return (
        <div key={item.url}>
          <MenuItem
            text={item.text}
            url={item.url}
            color={item.color}
          />
        </div>
      )
    })}
  </>
}

export default MenuItems
