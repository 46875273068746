import urljoin from "url-join";

const IPFS_URI_STARTING = "ipfs://";
const IPFS_URI_CUSTOM_GATEWAY = "https://ipfs.aspect.co/ipfs/";
export class IPFSUri {
  static isUri(uri: string): boolean {
    return uri.startsWith(IPFS_URI_STARTING);
  }

  static getUrl(uri: string): string | null {
    if (this.isUri(uri)) {
      const ipfsHash = uri.substring(IPFS_URI_STARTING.length);
      return urljoin(IPFS_URI_CUSTOM_GATEWAY, ipfsHash);
    }

    return null;
  }

  static toUri(ipfsHash: string): string {
    return `${IPFS_URI_STARTING}${ipfsHash}`;
  }
}

const AIRWEAVE_STARTING = "ar://";
const AIRWEAVE_GATEWAY = "https://arweave.net/";
class AirweaveUri {
  static isUri(uri: string): boolean {
    return uri.startsWith(AIRWEAVE_STARTING);
  }

  static getUrl(uri: string): string | null {
    if (this.isUri(uri)) {
      const airweaveHash = uri.substring(AIRWEAVE_STARTING.length);
      return urljoin(AIRWEAVE_GATEWAY, airweaveHash);
    }
    return null;
  }
}

class BasicUri {
  static isUri(uri: string): boolean {
    try {
      new URL(uri);
      return true;
    } catch (err) {
      console.log("[BasicUri]", uri, err);
      return false;
    }
  }

  static getUrl(uri: string): string | null {
    if (this.isUri(uri)) {
      return uri;
    }

    return null;
  }
}

const DATA_URI_STARTING = "data:";
function isDataUri(uri: string) {
  return uri.startsWith(DATA_URI_STARTING);
}

/**
 * @returns full url given the uri, null if invalid
 * - supports ipfs, airweave, basic urls
 */
export function getUrl(uri: string): string | null {
  // HACK: frontend has links to open urls, just set data uri to null because many browsers block opening data uri
  // we should figure out a way for a user the see the data uri values safely.
  if (isDataUri(uri)) {
    return null;
  }

  let url = IPFSUri.getUrl(uri);
  if (url) {
    return url;
  }

  url = AirweaveUri.getUrl(uri);
  if (url) {
    return url;
  }

  url = BasicUri.getUrl(uri);
  if (url) {
    return url;
  }

  return null;
}
