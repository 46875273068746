import BigNumber from "bignumber.js";

import {
  areHexStringsEqual,
  isBriqsContractAddress,
  safeToBigNumber,
} from "utils";
import * as paths from "utils/paths";
import {
  Asset,
  AssetOwner,
  ContractSchema,
  Event,
  EventType,
} from "utils/graphql/generated";
import * as uri from "utils/uri";
import { populateEvents, EventWithMetadata } from "utils/populate/event";
import {
  ExchangeOrderWithMetadata,
  getSellOrders,
  getBidOrders,
  getMinPriceSellOrder,
  getMaxPriceBidOrder,
  populateExchangeOrders,
} from "utils/populate/exchangeOrder";
import { populateProfile, ProfileWithMetadata } from "./profile";
import { populateContract, ContractWithMetadata } from "./contract";

// default image blur hash, renders a light grey color with no gradient
const IMAGE_BLUR_HASH_DEFAULT = "L1QTAg-;fQ-;_4fQfQfkfQfQfQfQ";

const INVALID_BRIQ_TOKEN_IDS = [
  "3606233581923302319481717770752954551525267283126588415254801983237070520320",
  "340224599361790042770291783724100815925870663925937655576520628335452094464",
  "767153621716524895791728913601565726938997337293329940083812577754444988416",
  "244473655277060537029078242970823531013987728979885109165931710247056965632",
];

function isValidAsset(contract_address: string, token_id: string): boolean {
  if (
    isBriqsContractAddress(contract_address) &&
    INVALID_BRIQ_TOKEN_IDS.includes(token_id)
  ) {
    return false;
  }

  return true;
}

const AssetWithMetadataInternalTypeName = "AssetWithMetadata";
export function isAssetWithMetadata(val: any) {
  return val?.__internal_type === AssetWithMetadataInternalTypeName;
}

/**
 * Helper fields for display purposes on the frontend
 */
export interface AssetWithMetadata extends Asset {
  __internal_type: string;
  eventsWithMetadata: EventWithMetadata[];
  exchangeOrdersWithMetadata: ExchangeOrderWithMetadata[];
  contractWithMetadata: ContractWithMetadata;

  // Asset metadata
  imageOriginalUrl: string | null;
  imageUrl: string | null;
  imageSmallUrl: string | null;
  imageMediumUrl: string | null;
  imageBlurHashWithDefault: string;

  animationUrl: string | null;
  externalUrl: string | null;
  tokenMetadataUrl: string | null;

  createdAt: Date | null;
  creatorProfile: ProfileWithMetadata | null;

  ownerData: AssetOwner;
  ownerProfile: ProfileWithMetadata;
  isOwner: boolean;
  totalOwnerCount: number;
  totalSupplyCount: string;

  safeBigNumberContractTokenId: BigNumber;

  // Order metadata
  sellOrders: ExchangeOrderWithMetadata[];
  bidOrders: ExchangeOrderWithMetadata[];
  minPriceSellOrder: ExchangeOrderWithMetadata | null;
  minPriceSellOrderIncludingOwner: ExchangeOrderWithMetadata | null;
  maxPriceBidOrder: ExchangeOrderWithMetadata | null;

  routeUrl: string;

  isERC1155: boolean;

  // Is valid nft
  isValid: boolean;
}

export function populateAssets(
  assets: Asset[],
  currentAccountAddress: string
): AssetWithMetadata[] {
  return assets.map((asset) => {
    return populateAsset(asset, currentAccountAddress);
  });
}

export function populateAsset(
  asset: Asset,
  currentAccountAddress: string
): AssetWithMetadata {
  const eventsWithMetadata = asset.events
    ? populateEvents(asset.events, currentAccountAddress)
    : [];
  const exchangeOrdersWithMetadata = asset.exchange_orders
    ? populateExchangeOrders(asset.exchange_orders, currentAccountAddress)
    : [];
  const contractWithMetadata = populateContract(asset.contract);

  // Asset metadata
  const imageOriginalUrl = asset.image_uri ? uri.getUrl(asset.image_uri) : null;
  const imageUrl = asset.image_url_copy
    ? uri.getUrl(asset.image_url_copy)
    : imageOriginalUrl;
  const imageSmallUrl = asset.image_small_url_copy
    ? uri.getUrl(asset.image_small_url_copy)
    : imageUrl;
  const imageMediumUrl = asset.image_medium_url_copy
    ? uri.getUrl(asset.image_medium_url_copy)
    : imageUrl;

  // DEVNOTE: blurhashes have a 6 char minimum, this should never happen but enforced incase
  let imageBlurHashWithDefault =
    !asset.image_blur_hash || asset.image_blur_hash.length < 6
      ? IMAGE_BLUR_HASH_DEFAULT
      : asset.image_blur_hash;

  const animationUrl = asset.animation_uri
    ? uri.getUrl(asset.animation_uri)
    : null;
  const externalUrl = asset.external_uri
    ? uri.getUrl(asset.external_uri)
    : null;
  const tokenMetadataUrl = asset.token_uri ? uri.getUrl(asset.token_uri) : null;

  const createdAt = getCreatedAt(asset);
  const creatorProfile = getCreatorProfile(asset, currentAccountAddress);
  const currentOwner = asset.owners?.find((owner) => {
    return areHexStringsEqual(owner.account_address, currentAccountAddress);
  });
  const totalOwnerCount = asset.owners?.length || 0;
  let totalSupplyCount = safeToBigNumber(0);
  asset.owners?.forEach((owner) => {
    totalSupplyCount = totalSupplyCount.plus(safeToBigNumber(owner.quantity));
  });

  let owner = asset.owners![0];
  if (currentOwner) {
    owner = currentOwner;
  }
  const ownerData = owner;
  const ownerProfile = populateProfile(
    owner.profile || {
      account_address: owner.account_address,
    },
    currentAccountAddress
  );
  const isOwner = !!currentOwner;

  const safeBigNumberContractTokenId = safeToBigNumber(asset.token_id);

  // Order Metadata
  const sellOrders = getSellOrders(exchangeOrdersWithMetadata);
  const bidOrders = getBidOrders(exchangeOrdersWithMetadata);
  const minPriceSellOrder = getMinPriceSellOrder(
    exchangeOrdersWithMetadata,
    currentAccountAddress
  );
  const minPriceSellOrderIncludingOwner = getMinPriceSellOrder(
    exchangeOrdersWithMetadata,
    ""
  );
  const maxPriceBidOrder = getMaxPriceBidOrder(exchangeOrdersWithMetadata);

  const routeUrl = paths.asset(asset.contract_address, asset.token_id);
  const isERC1155 = asset.contract.schema === ContractSchema.Erc1155;
  const isValid = isValidAsset(asset.contract_address, asset.token_id);

  return {
    ...asset,

    __internal_type: AssetWithMetadataInternalTypeName,

    eventsWithMetadata,
    exchangeOrdersWithMetadata,
    contractWithMetadata,

    imageOriginalUrl,
    imageUrl,
    imageSmallUrl,
    imageMediumUrl,
    imageBlurHashWithDefault,

    animationUrl,
    externalUrl,
    tokenMetadataUrl,
    createdAt,
    creatorProfile,

    ownerData,
    ownerProfile,
    isOwner,
    totalOwnerCount,
    totalSupplyCount: totalSupplyCount.toFixed(),

    safeBigNumberContractTokenId,

    sellOrders,
    bidOrders,
    minPriceSellOrder,
    minPriceSellOrderIncludingOwner,
    maxPriceBidOrder,

    routeUrl,
    isERC1155,
    isValid,
  };
}

function getCreatorProfile(
  asset: Asset,
  currentAccountAddress: string
): ProfileWithMetadata | null {
  if (!asset.events) {
    return null;
  }
  const mintEvent = getMintEvent(asset.events);
  if (mintEvent === null) {
    return null;
  }

  if (mintEvent.to_address_profile) {
    return populateProfile(mintEvent.to_address_profile, currentAccountAddress);
  }
  return populateProfile(
    {
      account_address: mintEvent.to_address,
    },
    currentAccountAddress
  );
}

function getCreatedAt(asset: Asset): Date | null {
  if (!asset.events) {
    return null;
  }
  const mintEvent = getMintEvent(asset.events);
  if (mintEvent === null) {
    return null;
  }
  return mintEvent.timestamp;
}

function getMintEvent(events: Event[]): Event | null {
  for (var i = 0; i < events.length; i++) {
    const event = events[i];
    if (event.type === EventType.Mint) {
      return event;
    }
  }
  return null;
}
