export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** GraphQL Scalar representing the BigNumber type. */
  BigNumber: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** GraphQL Scalar representing a Hex String. */
  HexString: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type Asset = {
  __typename?: 'Asset';
  animation_uri?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONObject']>;
  contract: Contract;
  contract_address: Scalars['HexString'];
  description?: Maybe<Scalars['String']>;
  events?: Maybe<Array<Event>>;
  exchange_orders?: Maybe<Array<ExchangeOrder>>;
  external_uri?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image_blur_hash?: Maybe<Scalars['String']>;
  image_medium_url_copy?: Maybe<Scalars['String']>;
  image_small_url_copy?: Maybe<Scalars['String']>;
  image_uri?: Maybe<Scalars['String']>;
  image_url_copy?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owners?: Maybe<Array<AssetOwner>>;
  token_id: Scalars['BigNumber'];
  token_uri?: Maybe<Scalars['String']>;
};

export type AssetCategoryFilter = {
  trait_type: Scalars['String'];
  value: Scalars['String'];
};

export type AssetConnection = {
  __typename?: 'AssetConnection';
  edges: Array<AssetEdge>;
  pageInfo: PageInfo;
};

export type AssetEdge = {
  __typename?: 'AssetEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
  node: Asset;
};

export type AssetInput = {
  contract_address: Scalars['HexString'];
  token_id: Scalars['BigNumber'];
};

export type AssetOwner = {
  __typename?: 'AssetOwner';
  account_address: Scalars['HexString'];
  profile?: Maybe<Profile>;
  quantity: Scalars['BigNumber'];
};

export type AssetsInput = {
  assets_category_filter?: InputMaybe<Array<AssetCategoryFilter>>;
  assets_sort_by: AssetsSortBy;
  contract_address?: InputMaybe<Scalars['HexString']>;
  created_by_address?: InputMaybe<Scalars['HexString']>;
  filter?: InputMaybe<Scalars['String']>;
  owner_address?: InputMaybe<Scalars['HexString']>;
};

export enum AssetsSortBy {
  MintedAtNewestToOldest = 'MINTED_AT_NEWEST_TO_OLDEST',
  MintedAtOldestToNewest = 'MINTED_AT_OLDEST_TO_NEWEST',
  PriceHighToLow = 'PRICE_HIGH_TO_LOW',
  PriceLowToHigh = 'PRICE_LOW_TO_HIGH'
}

export type AuthChallengeInput = {
  account_address: Scalars['HexString'];
};

export type AuthChallengeResponse = {
  __typename?: 'AuthChallengeResponse';
  nonce: Scalars['Int'];
};

export type Contract = {
  __typename?: 'Contract';
  banner_image_url?: Maybe<Scalars['String']>;
  contract_address: Scalars['HexString'];
  contract_info?: Maybe<ContractInfo>;
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_custom?: Maybe<Scalars['String']>;
  schema: ContractSchema;
  symbol?: Maybe<Scalars['String']>;
};

export type ContractInfo = {
  __typename?: 'ContractInfo';
  attribute_types?: Maybe<Scalars['JSONObject']>;
  floor_price: Scalars['BigNumber'];
  number_of_items: Scalars['BigNumber'];
  number_of_owners: Scalars['BigNumber'];
};

export type ContractInput = {
  contract_address: Scalars['HexString'];
};

export enum ContractSchema {
  Erc721 = 'ERC721',
  Erc1155 = 'ERC1155'
}

export type Event = {
  __typename?: 'Event';
  asset: Asset;
  from_address: Scalars['HexString'];
  from_address_profile?: Maybe<Profile>;
  id: Scalars['String'];
  payment_address?: Maybe<Scalars['HexString']>;
  payment_amount?: Maybe<Scalars['BigNumber']>;
  payment_amount_per?: Maybe<Scalars['BigNumber']>;
  quantity: Scalars['BigNumber'];
  timestamp: Scalars['DateTime'];
  to_address: Scalars['HexString'];
  to_address_profile?: Maybe<Profile>;
  transaction_hash?: Maybe<Scalars['HexString']>;
  type: EventType;
};

export type EventConnection = {
  __typename?: 'EventConnection';
  edges: Array<EventEdge>;
  pageInfo: PageInfo;
};

export type EventEdge = {
  __typename?: 'EventEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
  node: Event;
};

export enum EventType {
  BidErc721 = 'BID_ERC721',
  BidErc721Cancel = 'BID_ERC721_CANCEL',
  BidErc721Sale = 'BID_ERC721_SALE',
  BidErc1155 = 'BID_ERC1155',
  BidErc1155Cancel = 'BID_ERC1155_CANCEL',
  BidErc1155Sale = 'BID_ERC1155_SALE',
  Burn = 'BURN',
  ListErc721 = 'LIST_ERC721',
  ListErc721Cancel = 'LIST_ERC721_CANCEL',
  ListErc721Sale = 'LIST_ERC721_SALE',
  ListErc1155 = 'LIST_ERC1155',
  ListErc1155Cancel = 'LIST_ERC1155_CANCEL',
  ListErc1155Sale = 'LIST_ERC1155_SALE',
  Mint = 'MINT',
  Transfer = 'TRANSFER'
}

export type EventsInput = {
  account_address?: InputMaybe<Scalars['HexString']>;
  contract_address?: InputMaybe<Scalars['HexString']>;
};

export type ExchangeOrder = {
  __typename?: 'ExchangeOrder';
  asset: Asset;
  end_time: Scalars['BigNumber'];
  exchange_address: Scalars['HexString'];
  expected_items: Array<ExchangeOrderItemWithRecipient>;
  offered_items: Array<ExchangeOrderItem>;
  offerer: Scalars['HexString'];
  offerer_profile: Profile;
  order_hash: Scalars['String'];
  payment_address: Scalars['HexString'];
  payment_amount: Scalars['BigNumber'];
  payment_amount_per: Scalars['BigNumber'];
  quantity: Scalars['BigNumber'];
  salt: Scalars['BigNumber'];
  sig_r: Scalars['String'];
  sig_s: Scalars['String'];
  start_time: Scalars['BigNumber'];
  status: ExchangeOrderStatus;
  type: ExchangeOrderType;
};

export type ExchangeOrderItem = {
  __typename?: 'ExchangeOrderItem';
  amount: Scalars['BigNumber'];
  contract_address: Scalars['HexString'];
  identifier: Scalars['BigNumber'];
  type: Scalars['Int'];
};

export type ExchangeOrderItemInput = {
  amount: Scalars['BigNumber'];
  contract_address: Scalars['HexString'];
  identifier: Scalars['BigNumber'];
  type: Scalars['Int'];
};

export type ExchangeOrderItemWithRecipient = {
  __typename?: 'ExchangeOrderItemWithRecipient';
  amount: Scalars['BigNumber'];
  contract_address: Scalars['HexString'];
  identifier: Scalars['BigNumber'];
  recipient: Scalars['HexString'];
  type: Scalars['Int'];
};

export type ExchangeOrderItemWithRecipientInput = {
  amount: Scalars['BigNumber'];
  contract_address: Scalars['HexString'];
  identifier: Scalars['BigNumber'];
  recipient: Scalars['HexString'];
  type: Scalars['Int'];
};

export enum ExchangeOrderStatus {
  Cancelled = 'CANCELLED',
  Filled = 'FILLED',
  Invalid = 'INVALID',
  NotFilled = 'NOT_FILLED'
}

export enum ExchangeOrderType {
  BidErc721 = 'BID_ERC721',
  BidErc1155 = 'BID_ERC1155',
  ListErc721 = 'LIST_ERC721',
  ListErc1155 = 'LIST_ERC1155'
}

export type GetProfilePresignedUrlsInput = {
  account_address: Scalars['HexString'];
  sig_r: Scalars['String'];
  sig_s: Scalars['String'];
};

export type GetProfilePresignedUrlsResponse = {
  __typename?: 'GetProfilePresignedUrlsResponse';
  banner_media_id: Scalars['String'];
  banner_presigned_url: Scalars['String'];
  profile_media_id: Scalars['String'];
  profile_presigned_url: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  refreshAsset: Scalars['Boolean'];
  setExchangeOrder: Scalars['Boolean'];
  updateProfile: Scalars['Boolean'];
};


export type MutationRefreshAssetArgs = {
  input: RefreshAssetInput;
};


export type MutationSetExchangeOrderArgs = {
  input: SetExchangeOrderInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  account_address: Scalars['HexString'];
  banner_image_url?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  profile_image_url?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type ProfileInput = {
  profile_identifier: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  asset: Asset;
  assets: AssetConnection;
  challengeAuth: AuthChallengeResponse;
  contract: Contract;
  contracts: Array<Contract>;
  events: EventConnection;
  generateIpfsKey: Scalars['String'];
  getProfilePresignedUrls: GetProfilePresignedUrlsResponse;
  profile: Profile;
  search: SearchResult;
};


export type QueryAssetArgs = {
  input: AssetInput;
};


export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  input: AssetsInput;
  last?: InputMaybe<Scalars['Float']>;
};


export type QueryChallengeAuthArgs = {
  input: AuthChallengeInput;
};


export type QueryContractArgs = {
  input: ContractInput;
};


export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  input: EventsInput;
  last?: InputMaybe<Scalars['Float']>;
};


export type QueryGetProfilePresignedUrlsArgs = {
  input: GetProfilePresignedUrlsInput;
};


export type QueryProfileArgs = {
  input: ProfileInput;
};

export type RefreshAssetInput = {
  id: Scalars['String'];
};

export type SearchInput = {
  count: Scalars['Int'];
  filter: Scalars['String'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  assets?: Maybe<Array<Asset>>;
  contracts?: Maybe<Array<Contract>>;
  profiles?: Maybe<Array<Profile>>;
};


export type SearchResultAssetsArgs = {
  input: SearchInput;
};


export type SearchResultContractsArgs = {
  input: SearchInput;
};


export type SearchResultProfilesArgs = {
  input: SearchInput;
};

export type SetExchangeOrderInput = {
  end_time: Scalars['BigNumber'];
  exchange_address: Scalars['HexString'];
  expected_items: Array<ExchangeOrderItemWithRecipientInput>;
  offered_items: Array<ExchangeOrderItemInput>;
  offerer: Scalars['HexString'];
  salt: Scalars['BigNumber'];
  sig_r: Scalars['String'];
  sig_s: Scalars['String'];
  start_time: Scalars['BigNumber'];
};

export type UpdateProfileInput = {
  account_address: Scalars['HexString'];
  banner_media_id?: InputMaybe<Scalars['String']>;
  biography?: InputMaybe<Scalars['String']>;
  profile_media_id?: InputMaybe<Scalars['String']>;
  sig_r: Scalars['String'];
  sig_s: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};
