import React, { useEffect } from "react";
import axios from "axios";
import styles from "styles/Status.module.css";
import useStarknetStatusStore, {
  STARKNET_STATUS,
  StarknetStatusStrings,
} from "stores/useStarknetStatusStore";

function StarknetStatus() {
  const { status, changeStatus } = useStarknetStatusStore((state) => state);

  useEffect(() => {
    function checkStatuses(statuses: Array<any>) {
      for (let i = 0; i < statuses?.length; i += 1) {
        const status = statuses[i];
        if (checkLiveStatus(status.name)) {
          if (status.hasFailures) {
            console.log("status is offline:", status);
            changeStatus(STARKNET_STATUS.OFFLINE);
            return;
          } else if (status.isDegraded) {
            console.log("status is being degraded:", status);
            changeStatus(STARKNET_STATUS.DEGRADED);
            return;
          }
        }
      }
      changeStatus(STARKNET_STATUS.ONLINE);
    }

    async function requestAndCheck() {
      const statuses = await getStatuses();
      checkStatuses(statuses);
    }

    requestAndCheck();
    const fiveMinInterval = setInterval(() => {
      requestAndCheck();
    }, 300000);

    return () => clearInterval(fiveMinInterval);
  }, [changeStatus]);

  const { color, text } = statusInfo(status);

  return (
    <a
      href="https://aspect.checklyhq.com/"
      target="_blank"
      rel="noreferrer"
      className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs ${color} cursor-pointer`}
    >
      <svg className={`h-2 w-2`} fill="currentColor" viewBox="0 0 8 8">
        <circle cx={4} cy={4} r={3} />
      </svg>
      <span className="ml-1.5 font-sans font-bold">{text}</span>
    </a>
  );
}

function checkLiveStatus(statusName: string) {
  return (
    statusName === "get_transaction_status" ||
    statusName === "call_contract" ||
    statusName === "add_transaction | DEPLOY" ||
    statusName === "add_transaction | INVOKE_FUNCTION"
  );
}

async function getStatuses() {
  try {
    const { data } = await axios.get(
      "https://starknet-status-lambda.vercel.app/api/status"
    );
    return data;
  } catch (error) {
    console.error(error);
  }
}

function statusInfo(status: StarknetStatusStrings) {
  if (status === STARKNET_STATUS.ONLINE) {
    return { color: "bg-green-100 text-green-800 green-pulse", text: "online" };
  } else if (status === STARKNET_STATUS.OFFLINE) {
    return {
      color: `bg-red-100 text-red-800 ${styles.redPulse}`,
      text: "offline",
    };
  } else if (status === STARKNET_STATUS.DEGRADED) {
    return {
      color: `bg-yellow-100 text-yellow-800 ${styles.yellowPulse}`,
      text: "degraded",
    };
  }
  return { color: "green", text: "online" };
}

export default StarknetStatus;
