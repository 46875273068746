import create, { SetState } from "zustand";

export enum STARKNET_STATUS {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  DEGRADED = "DEGRADED",
}

export type StarknetStatusStrings = keyof typeof STARKNET_STATUS;

type StarknetStatusStore = {
  status: StarknetStatusStrings;
  changeStatus: (newStatus: StarknetStatusStrings) => void;
  enterAnyway: boolean;
  allowEnterAnyway: () => void;
};

const useStarknetStatusStore = create<StarknetStatusStore>(
  (set: SetState<StarknetStatusStore>) => ({
    status: STARKNET_STATUS.ONLINE,
    changeStatus: (newStatus) => {
      set(() => ({
        status: newStatus,
      }));
    },
    enterAnyway: false,
    allowEnterAnyway: () => {
      set(() => ({
        enterAnyway: true,
      }));
    },
  })
);

export default useStarknetStatusStore;
