import * as ethers from "ethers";
import { safeToBigNumber } from "utils";

// DEVNOTE: we should plumb this logic everywhere to use symbol and decimals when we support multiple types of tokens

type erc20Token = {
  name: string;
  symbol: string;
  decimals: number;
  address: string;
};

export const ETH_TOKEN: erc20Token = {
  name: "Ether",
  symbol: "ETH",
  decimals: 18,
  address: "0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7",
};

// Converts a UI token amount
export function toTokenValue(amount: number | string): string {
  return ethers.utils
    .parseUnits(safeToBigNumber(amount).toFixed(), ETH_TOKEN.decimals)
    .toString();
}

// Converts a token amount to the view amount
export function toTokenView(amount: number | string): string {
  return ethers.utils
    .formatUnits(safeToBigNumber(amount).toFixed(), ETH_TOKEN.decimals)
    .toString();
}
