import React from "react";
import Discord from "./Icons/Discord";
import Twitter from "./Icons/Twitter";
import Telegram from "./Icons/Telegram";

const socials = [
  {
    name: "Discord",
    href: "https://discord.gg/aR2U7KtbgD",
    icon: (props: any) => <Discord {...props} />,
    hover: "hover:text-red-400",
  },
  {
    name: "Twitter",
    href: "https://twitter.com/aspectdotco",
    icon: (props: any) => <Twitter {...props} />,
    hover: "hover:text-yellow-400",
  },
  {
    name: "Telegram",
    href: "https://t.me/aspectco",
    icon: (props: any) => <Telegram {...props} />,
    hover: "hover:text-pink-400",
  },
];

function Socials() {
  return (
    <>
      {socials.map((item) => (
        <a key={item.name} href={item.href} className={item.hover}>
          <span className="sr-only">{item.name}</span>
          <item.icon className="h-6 w-6" aria-hidden="true" />
        </a>
      ))}
    </>
  );
}

export default Socials;
