import React from "react";
import ContentLoader from "react-content-loader";

function WalletSkeleton() {
  return (
    <ContentLoader
      uniqueKey="WalletSkeleton"
      className="hover:bg-gray-200 rounded-full cursor-pointer mr-5"
      viewBox="0 0 156 48"
      width={156}
      height={48}
    >
      <rect x="58" y="18" rx="1" ry="1" width="84" height="12" />
      <circle cx="28" cy="24" r="17" />
    </ContentLoader>
  );
}

export default WalletSkeleton;
