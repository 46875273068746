import useWalletsStore from "stores/useWalletsStore";

function ConnectWalletButton() {
  const { connectStarknetWallet } = useWalletsStore((state) => state);

  return (
    <div
      onClick={() => connectStarknetWallet()}
      className="rounded-full whitespace-nowrap shadow-md text-white bg-black px-3.5 py-1.5 text-sm font-bold cursor-pointer"
    >
      connect wallet
    </div>
  );
}

export default ConnectWalletButton;
