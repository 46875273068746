export type colorType = "pink" | "red" | "yellow";

export function animation(speed: number) {
  return `transition-all duration-${speed} ease-in-out`;
}

export const animateCard = animation(200);

export function isHoverTextColor(hover: boolean, color: colorType) {
  // DEVNOTE: for some reason bg-red-400 never renders in the header background
  // reason unknown
  // using color hash instead of tailwind as a result
  if (hover) {
    if (color === "pink") {
      return "#f472b6";
    } else if (color === "yellow") {
      return "#facc15";
    } else if (color === "red") {
      return "#f87171";
    } else {
      return "";
    }
  }
  return "";
}

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
