import {
  truncateAddress,
  truncateIdentifier,
  safeToBigNumber,
  unsafeBigNumberToReadableString,
} from "utils";
import { AssetCategoryFilter, Contract } from "utils/graphql/generated";
import * as paths from "utils/paths";
import { toTokenView } from "utils/tokens";

import { CategoryType } from "components/modules/AssetScroll/CategoriesSelect";

const ContractWithMetadataInternalTypeName = "ContractWithMetadata";
export function isContractWithMetadata(val: any) {
  return val?.__internal_type === ContractWithMetadataInternalTypeName;
}

export interface ContractWithMetadata extends Contract {
  __internal_type: string;

  displayContractAddressShort: string;

  displayName: string | null;
  displayNameShort: string | null;

  displayIdentifier: string;
  displayIdentifierShort: string;

  displayProfileImageUrl: string | null;
  displayBannerImageUrl: string | null;

  displayNumberOfItems: string | null;
  displayNumberOfOwners: string | null;
  displayFloorPrice: string | null;
  displayTotalVolume: string | null;

  routeUrl: string;

  categories: CategoryType[] | null;
}

export function populateContracts(
  contracts: Contract[]
): ContractWithMetadata[] {
  return contracts.map((contract) => {
    return populateContract(contract);
  });
}

export function populateContract(contract: Contract): ContractWithMetadata {
  const displayContractAddressShort = truncateAddress(
    contract.contract_address
  );
  // custom integrations for collections
  let displayName =
    contract.name_custom || contract.name || contract.contract_address;
  if (displayName === "\x01") {
    // HACK for weird input
    displayName = contract.contract_address;
  }
  const displayNameShort = displayName && truncateIdentifier(displayName);

  const displayProfileImageUrl = contract.image_url || null;
  const displayBannerImageUrl = contract.banner_image_url || null;

  const displayIdentifier = displayName || contract.contract_address;
  const displayIdentifierShort =
    displayNameShort || displayContractAddressShort;

  const contract_info = contract.contract_info;

  let safeBigNumberNumberOfItems = null;
  let displayNumberOfItems = null;

  let safeBigNumberNumberOfOwners = null;
  let displayNumberOfOwners = null;

  let safeBigNumberFloorPrice = null;
  let displayFloorPrice = null;

  let safeBigNumberTotalVolume = null;
  let displayTotalVolume = null;

  if (contract_info?.number_of_items !== undefined) {
    safeBigNumberNumberOfItems = safeToBigNumber(contract_info.number_of_items);
    displayNumberOfItems = unsafeBigNumberToReadableString(
      safeBigNumberNumberOfItems
    );
  }

  if (contract_info?.number_of_owners !== undefined) {
    safeBigNumberNumberOfOwners = safeToBigNumber(
      contract_info.number_of_owners
    );
    displayNumberOfOwners = unsafeBigNumberToReadableString(
      safeBigNumberNumberOfOwners
    );
  }

  if (contract_info?.floor_price !== undefined) {
    safeBigNumberFloorPrice = safeToBigNumber(contract_info.floor_price);
    displayFloorPrice = unsafeBigNumberToReadableString(
      safeToBigNumber(toTokenView(safeBigNumberFloorPrice.toFixed()))
    );
  }

  const routeUrl = paths.collection(contract.contract_address);

  let categories: CategoryType[] | null = null;
  if (contract_info?.attribute_types !== undefined) {
    for (const [key, value] of Object.entries(contract_info.attribute_types)) {
      if (categories === null) {
        categories = [];
      }
      const options: AssetCategoryFilter[] = [];
      contract_info.attribute_types[key].forEach((v: any) => {
        options.push({
          trait_type: key,
          value: v,
        });
      });
      categories.push({
        trait_type: key,
        options: options,
      });
    }
  }

  return {
    ...contract,
    __internal_type: ContractWithMetadataInternalTypeName,

    displayContractAddressShort,
    displayName,
    displayNameShort,
    displayIdentifier,
    displayIdentifierShort,
    displayProfileImageUrl,
    displayBannerImageUrl,

    displayNumberOfItems,
    displayNumberOfOwners,
    displayFloorPrice,
    displayTotalVolume,

    routeUrl,

    categories,
  };
}
