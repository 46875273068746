import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

import Logo from "./Logo";
import MenuItems from "./MenuItems";
import StarknetStatus from "./StarknetStatus";
import WalletButton from "./WalletButton";
import Search from "./Search";

function Header({ isAssetPage }: { isAssetPage: boolean }) {
  return (
    <Popover
      className={`px-4 sm:px-6 lg:px-8 sticky w-full top-0 ${
        isAssetPage ? "bg-gray-100" : "bg-white"
      }`}
      style={{ zIndex: 5 }}
    >
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center py-2">
          <div className="flex mr-8">
            <Logo />
          </div>
          <Search />
          <div className="-mr-2 -my-2 ml-4 lg:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6 text-black" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden lg:flex space-x-8 mx-10">
            <MenuItems />
          </Popover.Group>
          <div className="hidden lg:flex items-center justify-end lg:flex-1 gap-x-0 lg:gap-x-5">
            <StarknetStatus />
            <WalletButton isMobile={false} />
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="z-10 absolute top-0 inset-x-0 transition transform origin-top-right lg:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between flex-wrap gap-y-2 gap-x-5">
                <Logo />
                <StarknetStatus />
                <WalletButton isMobile={true} />

                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6 text-black" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <MenuItems />
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
      {isAssetPage ? null : <hr className="absolute w-full left-0" />}
    </Popover>
  );
}

export default Header;
