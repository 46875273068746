import { useState } from "react";
import gradient from "random-gradient";
import { hexStringToBytes, bytesToHexString, isHexString } from "utils";
import { classNames } from "utils/styles";

/**
 * Helper wrapper component to display the given gradient if the image fails to load or does not exist
 */
function FallbackGradientImage({
  gradientHash,
  imageUrl,
  className,
}: {
  gradientHash: string;
  imageUrl: string | null;
  className: string;
}) {
  // format gradientHash if it is a hexString, the reason we need this is that in some cases the hexString in
  // have starting 0s, eg 0x5 vs 0x05
  if (isHexString(gradientHash)) {
    gradientHash = bytesToHexString(hexStringToBytes(gradientHash));
  }

  const [imageError, setImageError] = useState(false);

  const shouldUseGradient = imageError || !imageUrl || imageUrl.length <= 0;
  return (
    <>
      {shouldUseGradient ? (
        <div
          style={{
            background: gradient(gradientHash ? gradientHash : "0x0"),
          }}
          className={className}
        />
      ) : (
        <img
          className={classNames("object-cover", className)}
          src={imageUrl}
          onError={({ currentTarget }) => {
            setImageError(true);
          }}
        />
      )}
    </>
  );
}

export default FallbackGradientImage;
