import React from "react";
import { ExclamationIcon, XIcon } from "@heroicons/react/solid";

function WarningStarknetAlpha({ closeWarning }: { closeWarning: () => void }) {
  return (
    <div className="rounded-md bg-yellow-50 p-4 mt-6 max-w-7xl mx-auto">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">Alpha Notice:</h3>
          <div className="mt-2 text-xs text-yellow-700 leading-5">
            <p>
              Aspect runs on{" "}
              <a
                target="_blank"
                href="https://starknet.io/what-is-starknet/"
                rel="noreferrer"
                className="underline"
              >
                StarkNet
              </a>{" "}
              testnet, which is currently in{" "}
              <span className="font-bold">alpha</span>. Transactions could take
              up to <span className="font-bold">10 minutes</span>. StarkWare is
              working on making it faster . Thanks for your patience!
            </p>
          </div>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-yellow-600"
              onClick={closeWarning}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WarningStarknetAlpha;
