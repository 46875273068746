import React, { useState } from "react";

import SearchBar from "./SearchBar";
import SearchIcon from "components/modules/Icons/SearchIcon";

import useKeyPress from "hooks/common/useKeyPress";
import usePlatformType from "hooks/common/usePlatformType";

function Search() {
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const platform = usePlatformType();

  useKeyPress("k", () => setIsSearchBarOpen(true));

  return (
    <>
      <button
        type="button"
        className="flex items-center w-full text-left space-x-3 px-4 h-9 bg-white ring-1 ring-slate-900/10 shadow-sm rounded-full text-slate-400"
        onClick={() => setIsSearchBarOpen(true)}
      >
        <SearchIcon />
        <span className="flex-auto font-bold text-sm">Search…</span>
        {(() => {
          if (platform?.toLowerCase().includes("windows")) {
            return (
              <kbd className="font-sans font-semibold ">
                <abbr title="Control" className="no-underline text-slate-300">
                  Ctrl{" "}
                </abbr>{" "}
                K
              </kbd>
            );
          } else if (platform?.toLowerCase().includes("mac")) {
            return (
              <kbd className="font-sans font-semibold ">
                <abbr title="Command" className="no-underline text-slate-300 ">
                  ⌘
                </abbr>{" "}
                K
              </kbd>
            );
          }
          return <></>;
        })()}
      </button>
      <SearchBar
        open={isSearchBarOpen}
        closeModal={() => setIsSearchBarOpen(false)}
      />
    </>
  );
}

export default Search;
