import * as starknet from "starknet";
import BigNumber from "bignumber.js";

import {
  areHexStringsEqual,
  safeBigNumberToUint256,
  safeToBigNumber,
} from "utils";
import { ExchangeOrder, ExchangeOrderType } from "utils/graphql/generated";
import { toTokenView } from "utils/tokens";
import { populateProfile, ProfileWithMetadata } from "utils/populate/profile";
import { AssetWithMetadata, populateAsset } from "utils/populate/asset";

function isBidType(type: ExchangeOrderType) {
  return [ExchangeOrderType.BidErc721, ExchangeOrderType.BidErc1155].includes(
    type
  );
}

function isListingType(type: ExchangeOrderType) {
  return [ExchangeOrderType.ListErc721, ExchangeOrderType.ListErc1155].includes(
    type
  );
}

export interface ExchangeOrderWithMetadata extends ExchangeOrder {
  displayPaymentAmount: string;
  displayPaymentAmountPer: string;
  displayPaymentFull: string;

  safeBigNumberPaymentAmount: BigNumber;

  assetContractAddress: string;
  safeBigNumberAssetTokenId: BigNumber;
  safeUint256AssetTokenId: starknet.uint256.Uint256;

  offererProfile: ProfileWithMetadata;

  assetWithMetadata: AssetWithMetadata | null;

  isBid: boolean;
  isListing: boolean;
}

export function populateExchangeOrders(
  exchangeOrders: ExchangeOrder[],
  currentAccountAddress: string
): ExchangeOrderWithMetadata[] {
  return exchangeOrders.map((exchangeOrder) => {
    return populateExchangeOrder(exchangeOrder, currentAccountAddress);
  });
}

export function populateExchangeOrder(
  exchangeOrder: ExchangeOrder,
  currentAccountAddress: string
): ExchangeOrderWithMetadata {
  const assetData = extractAssetData(exchangeOrder);

  const displayPaymentAmount = toTokenView(exchangeOrder.payment_amount);
  const displayPaymentAmountPer = toTokenView(exchangeOrder.payment_amount_per);
  const displayPaymentFull = `${exchangeOrder.quantity} x ${displayPaymentAmountPer}`;

  const safeBigNumberPaymentAmount = safeToBigNumber(
    exchangeOrder.payment_amount
  );

  const assetContractAddress = assetData.asset_contract_address;
  const safeBigNumberAssetTokenId = safeToBigNumber(assetData.asset_token_id);
  const safeUint256AssetTokenId = safeBigNumberToUint256(
    safeBigNumberAssetTokenId
  );

  const offererProfile = populateProfile(
    exchangeOrder.offerer_profile || {
      account_address: exchangeOrder.offerer,
    },
    currentAccountAddress
  );

  const assetWithMetadata = exchangeOrder.asset
    ? populateAsset(exchangeOrder.asset, currentAccountAddress)
    : null;

  const isBid: boolean = isBidType(exchangeOrder.type);
  const isListing: boolean = isListingType(exchangeOrder.type);

  return {
    ...exchangeOrder,

    displayPaymentAmount,
    displayPaymentAmountPer,
    displayPaymentFull,

    safeBigNumberPaymentAmount,

    assetContractAddress,
    safeBigNumberAssetTokenId,
    safeUint256AssetTokenId,

    offererProfile,
    assetWithMetadata,

    isBid,
    isListing,
  };
}

export function withoutOffererAddress(
  exchangeOrders: ExchangeOrderWithMetadata[],
  offererAddress: string
): ExchangeOrderWithMetadata[] {
  return exchangeOrders.filter((exchangeOrder: ExchangeOrderWithMetadata) => {
    return !areHexStringsEqual(offererAddress, exchangeOrder.offerer);
  });
}

export function getBidOrders(
  exchangeOrders: ExchangeOrderWithMetadata[]
): ExchangeOrderWithMetadata[] {
  return exchangeOrders.filter((exchangeOrder: ExchangeOrderWithMetadata) => {
    return isBidType(exchangeOrder.type);
  });
}

// TODO rename sell order to listing everywhere
export function getSellOrders(
  exchangeOrders: ExchangeOrderWithMetadata[]
): ExchangeOrderWithMetadata[] {
  return exchangeOrders.filter((exchangeOrder: ExchangeOrderWithMetadata) => {
    return isListingType(exchangeOrder.type);
  });
}

export function getMinPriceSellOrder(
  exchangeOrders: ExchangeOrderWithMetadata[],
  currentAccountAddress: string
): ExchangeOrderWithMetadata | null {
  const sellOrders = getSellOrders(exchangeOrders);
  if (!sellOrders || sellOrders.length === 0) {
    return null;
  }

  const sellOrdersWithoutCurrentAddress = withoutOffererAddress(
    sellOrders,
    currentAccountAddress
  );
  if (
    !sellOrdersWithoutCurrentAddress ||
    sellOrdersWithoutCurrentAddress.length === 0
  ) {
    return null;
  }

  return sellOrdersWithoutCurrentAddress.reduce(
    (prev: ExchangeOrderWithMetadata, curr: ExchangeOrderWithMetadata) => {
      const prevBN = starknet.number.toBN(prev.payment_amount_per);
      const currBN = starknet.number.toBN(curr.payment_amount_per);
      return prevBN.lt(currBN) ? prev : curr;
    }
  );
}

export function getMaxPriceBidOrder(
  exchangeOrders: ExchangeOrderWithMetadata[]
): ExchangeOrderWithMetadata | null {
  const bidOrders = getBidOrders(exchangeOrders);
  if (!bidOrders || bidOrders.length === 0) {
    return null;
  }

  return bidOrders.reduce(
    (prev: ExchangeOrderWithMetadata, curr: ExchangeOrderWithMetadata) => {
      const prevBN = starknet.number.toBN(prev.payment_amount_per);
      const currBN = starknet.number.toBN(curr.payment_amount_per);
      return prevBN.gt(currBN) ? prev : curr;
    }
  );
}

function extractAssetData(exchangeOrder: ExchangeOrder) {
  if (isBidType(exchangeOrder.type)) {
    return {
      asset_contract_address: exchangeOrder.expected_items[0].contract_address,
      asset_token_id: exchangeOrder.expected_items[0].identifier,
      asset_type: exchangeOrder.expected_items[0].type,
    };
  }
  return {
    asset_contract_address: exchangeOrder.offered_items[0].contract_address,
    asset_token_id: exchangeOrder.offered_items[0].identifier,
    asset_type: exchangeOrder.offered_items[0].type,
  };
}
