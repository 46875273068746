import { ReactNode, useState } from "react";
import Head from "next/head";

import NoticeBanner from "./NoticeBanner";
import Header from "./Header";
import Footer from "./Footer";

import WarningStarknetAlpha from "components/modules/Warning/WarningStarknetAlpha";
import MaintainenceNotice from "components/modules/MaintainenceNotice";
import WarningWalletUpgrade from "components/modules/Warning/WarningWalletUpgrade";

import * as config from "utils/config";
import { CardTagsMeta, getCardTags } from "utils/cardtags";

export enum PAGE_TYPE {
  NULL = "NULL",
  ASSET = "ASSET",
  FOUR_OH_FOUR = "FOUR_OH_FOUR",
  HOME = "HOME",
}

function Layout({
  children,
  pageType = PAGE_TYPE.NULL,
  cardTagsMeta,
}: {
  children: ReactNode;
  pageType?: PAGE_TYPE;
  cardTagsMeta?: CardTagsMeta;
}) {
  const shouldDisplayMaintainence =
    config.IS_MAINTAINENCE && pageType !== PAGE_TYPE.HOME;
  const isAssetPage = pageType === PAGE_TYPE.ASSET;
  const isFourOhFourPage = pageType === PAGE_TYPE.FOUR_OH_FOUR;

  const [showStarknetAlphaWarning, setShowStarknetAlphaWarning] = useState(
    !isFourOhFourPage
  );
  const [showWalletUpgradeWarning, setShowWalletUpgradeWarning] = useState(
    !isFourOhFourPage
  );

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <link rel="shortcun icon" href="/img/company/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />

        <link rel="apple-touch-icon" href="/img/company/logo-XS.png" />
        <link rel="manifest" href="/manifest.json" />

        <title>Aspect | Buy, Sell, and Create in the meta economy.</title>
        <meta
          name="description"
          content="Aspect is an NFT marketplace powered by StarkNet, a ZK rollup layer 2 on Ethereum. Buy, sell, and create in the meta economy."
        />

        {getCardTags(cardTagsMeta)}
      </Head>
      <NoticeBanner />
      <Header isAssetPage={isAssetPage} />
      <div className="mt-16">
        {shouldDisplayMaintainence ? <MaintainenceNotice /> : <>{children}</>}
      </div>
      {showWalletUpgradeWarning ? (
        <WarningWalletUpgrade
          closeWarning={() => setShowWalletUpgradeWarning(false)}
        />
      ) : null}
      {showStarknetAlphaWarning ? (
        <WarningStarknetAlpha
          closeWarning={() => setShowStarknetAlphaWarning(false)}
        />
      ) : null}
      <Footer />
    </>
  );
}

export default Layout;
