import { Fragment, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/solid";

import Category from "./Category";
import Preview from "./Preview";
import NotFound from "./NotFound";
import SearchLoading from "./SearchLoading";
import SearchError from "./SearchError";

import useSearchQuery from "hooks/data/useSearchQuery";
import { populateAssets } from "utils/populate/asset";
import { populateContracts } from "utils/populate/contract";
import { populateProfiles } from "utils/populate/profile";
import { classNames } from "utils/styles";

import useWalletsStore from "stores/useWalletsStore";

function SearchBar({
  open,
  closeModal,
}: {
  open: boolean;
  closeModal: Function;
}) {
  const router = useRouter();
  const [filter, setFilter] = useState("");
  const [filterInput, setFilterInput] = useState("");
  const { accountAddress } = useWalletsStore(state => state.starknetWallet)

  const skip = filter === "";
  const { search, error, loading } = useSearchQuery({
    filter: filter,
    count: 3,
    skip: skip,
  });

  const assetsWithMetadata = search?.assets
    ? populateAssets(search?.assets, accountAddress)
    : undefined;
  const contractsWithMetadata = search?.contracts
    ? populateContracts(search?.contracts)
    : undefined;
  const profilesWithMetadata = search?.profiles
    ? populateProfiles(search?.profiles, accountAddress)
    : undefined;

  const hasResults =
    (assetsWithMetadata && assetsWithMetadata.length > 0) ||
    (contractsWithMetadata && contractsWithMetadata.length > 0) ||
    (profilesWithMetadata && profilesWithMetadata.length > 0);
  const shouldDisplayResults = filter !== "" && !loading && !error;

  useEffect(() => {
    const timeoutId = setTimeout(() => setFilter(filterInput), 500);
    return () => clearTimeout(timeoutId);
  }, [filterInput]);

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setFilterInput("")}
    >
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20"
        onClose={() => closeModal()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Combobox
            as="div"
            className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
            value={null}
            onChange={(option: any) => {
              closeModal();
              router.push(option.routeUrl);
            }}
          >
            {({ activeOption }) => {
              return (
                <>
                  <div className="relative">
                    <SearchIcon
                      className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-sm text-gray-800 placeholder-gray-400 focus:ring-0"
                      placeholder="Search NFTs, Collections, and Users on Aspect…"
                      displayValue={() => filterInput}
                      onChange={(event) => setFilterInput(event.target.value)}
                      autoFocus
                    />
                    <div
                      className="cursor-pointer py-1 px-1.5 rounded-lg bg-slate-200 absolute top-3 right-3 text-xs font-bold text-gray-400"
                      onClick={() => closeModal()}
                    >
                      ESC
                    </div>
                  </div>

                  {(() => {
                    if (loading) {
                      return <SearchLoading />;
                    }
                    if (error) {
                      return <SearchError />;
                    }
                    if (shouldDisplayResults) {
                      if (hasResults) {
                        return (
                          <div className="flex divide-x divide-gray-100">
                            <div
                              className={classNames(
                                "max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4",
                                activeOption && "sm:h-96"
                              )}
                            >
                              {contractsWithMetadata &&
                                contractsWithMetadata.length > 0 && (
                                  <Category
                                    header="Collections"
                                    entities={contractsWithMetadata}
                                  />
                                )}
                              {assetsWithMetadata &&
                                assetsWithMetadata.length > 0 && (
                                  <Category
                                    header="NFTs"
                                    entities={assetsWithMetadata}
                                  />
                                )}
                              {profilesWithMetadata &&
                                profilesWithMetadata.length > 0 && (
                                  <Category
                                    header="Profiles"
                                    entities={profilesWithMetadata}
                                  />
                                )}
                            </div>
                            <Preview
                              activeOption={activeOption}
                              closeModal={closeModal}
                            />
                          </div>
                        );
                      } else {
                        return <NotFound />;
                      }
                    }
                  })()}
                </>
              );
            }}
          </Combobox>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}

export default SearchBar;
