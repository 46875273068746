import { Event } from "utils/graphql/generated";
import { toTokenView } from "utils/tokens";
import { AssetWithMetadata, populateAsset } from "utils/populate/asset";
import { populateProfile, ProfileWithMetadata } from "utils/populate/profile";

const EventWithMetadataInternalTypeName = "EventWithMetadata";
export function isEventWithMetadata(val: any) {
  return val?.__internal_type === EventWithMetadataInternalTypeName;
}

export interface EventWithMetadata extends Event {
  __internal_type: string;

  displayPaymentAmount: string | null;
  displayPaymentAmountPer: string | null;
  displayPaymentFull: string | null;
  assetWithMetdata: AssetWithMetadata | null;

  toProfile: ProfileWithMetadata;
  fromProfile: ProfileWithMetadata;
}

export function populateEvents(
  events: Event[],
  currentAccountAddress: string
): EventWithMetadata[] {
  return events.map((event) => {
    return populateEvent(event, currentAccountAddress);
  });
}

export function populateEvent(
  event: Event,
  currentAccountAddress: string
): EventWithMetadata {
  let displayPaymentFull: string | null = null;
  let displayPaymentAmount: string | null = null;
  let displayPaymentAmountPer: string | null = null;

  if (event.payment_amount && event.payment_amount_per) {
    displayPaymentAmount = toTokenView(event.payment_amount);
    displayPaymentAmountPer = toTokenView(event.payment_amount_per);
    displayPaymentFull = `${event.quantity} x ${displayPaymentAmountPer}`;
  }

  const toProfile = populateProfile(
    event.to_address_profile || {
      account_address: event.to_address,
    },
    currentAccountAddress
  );
  const fromProfile = populateProfile(
    event.from_address_profile || {
      account_address: event.from_address,
    },
    currentAccountAddress
  );

  const assetWithMetdata = event.asset
    ? populateAsset(event.asset, currentAccountAddress)
    : null;
  return {
    ...event,

    __internal_type: EventWithMetadataInternalTypeName,

    displayPaymentAmount,
    displayPaymentAmountPer,
    displayPaymentFull,

    assetWithMetdata,

    toProfile,
    fromProfile,
  };
}
