
import ConnectWalletButton from "components/modules/ConnectWalletButton";
import useWalletsStore, { WalletStatus } from "stores/useWalletsStore";
import WalletMenu from "./WalletMenu";
import WalletButtonSkeleton from "./WalletButtonSkeleton";

function WalletButton({ isMobile }: { isMobile: boolean }) {
  const {
    accountAddress,
    status: walletStatus,
    profileWithMetadata,
    isInitialized,
    isProfileLoading,
  } = useWalletsStore((state) => state.starknetWallet);

  if (!isInitialized || isProfileLoading) {
    return <WalletButtonSkeleton />;
  }

  return (
    <>
      {walletStatus === WalletStatus.CONNECTED ? (
        <WalletMenu
          accountAddress={accountAddress}
          profileWithMetdata={profileWithMetadata}
        />
      ) : (
        <div className={`${isMobile ? "" : "ml-5"}`}>
          <ConnectWalletButton />
        </div>
      )}
    </>
  );
}

export default WalletButton;
