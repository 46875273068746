import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

import * as sentry from "utils/sentry";

function useSafeClearApolloStore() {
  const apolloClient = useApolloClient();

  return useCallback(async () => {
    try {
      await apolloClient.clearStore();
    } catch (err) {
      console.log(err);
      sentry.captureError(err, {
        action: "clearing apollo client store",
      });
    }
  }, [apolloClient]);
}

export default useSafeClearApolloStore;
