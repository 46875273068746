import { useReducer } from "react";
import { XIcon } from "@heroicons/react/outline";

function NoticeBanner() {
  const [isOpen, close] = useReducer((s) => false, true);

  if (!isOpen) {
    return <></>;
  }

  return (
    <div className="relative bg-black hover:bg-gradient-to-r from-pink-400 via-red-400 to-yellow-400">
      <div className="max-w-7xl mx-auto py-2.5 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:text-center sm:px-16">
          <p className="font-medium text-white">
            <span className="inline font-sans font-medium">
              PlayOasis is now{" "}
              <a
                className="text-base font-medium underline cursor-pointer ml-0.5"
                href="https://twitter.com/aspectdotco/status/1529552342990761984"
                style={{ fontFamily: "LemonMilkPro" }}
                target="_blank"
                rel="noreferrer"
              >
                ASPECT
              </a>
            </span>
          </p>
        </div>
        <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
          <button
            type="button"
            className="flex p-1.5 rounded-md hover:bg-white hover:text-black text-white focus:outline-none focus:ring-2 focus:ring-white"
            onClick={close}
          >
            <span className="sr-only">Dismiss</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default NoticeBanner;
