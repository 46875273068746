import React, { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import * as paths from "utils/paths";
import {
  colorType,
  animation,
  isHoverTextColor,
  classNames,
} from "utils/styles";
import useSafeClearApolloStore from "hooks/data/useSafeClearApolloStore";

function MenuItem({
  text,
  color,
  url,
}: {
  text: string;
  color: colorType;
  url: string;
}) {
  const clearApolloStore = useSafeClearApolloStore();
  const [hover, changeHover] = useState(false);
  const router = useRouter();
  const isCurrentPath = paths.removeQueryParams(router.asPath) === url;
  const toRoute = isCurrentPath ? router.asPath : url;

  function isTextHighlight() {
    if (isCurrentPath) {
      if (color === "yellow") {
        return "text-yellow-400";
      } else if (color === "pink") {
        return "text-pink-400";
      } else if (color === "red") {
        return "text-red-400";
      }
    } else {
      return "";
    }
  }

  function isHoverAndCurrent() {
    if (!hover && isCurrentPath) {
      return "cursor-default";
    } else {
      return "cursor-pointer";
    }
  }

  const textColorCSS = isHoverTextColor(hover && !isCurrentPath, color);
  const textHighlight = isTextHighlight();
  const textPointer = isHoverAndCurrent();

  return (
    <Link href={toRoute} passHref>
      <a
        onClick={async () => {
          if (!isCurrentPath) {
            await clearApolloStore();
          }
        }}
      >
        <div
          onMouseOver={() => changeHover(true)}
          onMouseOut={() => changeHover(false)}
          className={classNames(
            `p-1.5 rounded-md font-semibold font-sans hover:underline`,
            textHighlight,
            animation(150),
            textPointer
          )}
          style={{ color: textColorCSS }}
        >
          {text}
        </div>
      </a>
    </Link>
  );
}

export default MenuItem;
