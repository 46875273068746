import { Combobox } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/solid";

import FallbackGradientImage from "components/modules/FallbackGradientImage";

import { classNames } from "utils/styles";

function CategoryList({
  id,
  value,
  displayUrl,
  name,
}: {
  id: string;
  value: any;
  displayUrl: string | null;
  name: string;
}) {
  return (
    <Combobox.Option
      key={id}
      value={value}
      className={({ active }) =>
        classNames(
          "flex cursor-default select-none items-center rounded-md p-2",
          active && "bg-gray-100 text-gray-900"
        )
      }
    >
      {({ active }) => {
        return (
          <>
            <FallbackGradientImage
              gradientHash={id}
              imageUrl={displayUrl}
              className="h-6 w-6 flex-none rounded-full"
            />
            <span className="ml-3 flex-auto truncate">{name}</span>
            {active && (
              <ChevronRightIcon
                className="ml-3 h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            )}
          </>
        );
      }}
    </Combobox.Option>
  );
}

export default CategoryList;
