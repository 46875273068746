import { ApolloError, gql, useQuery } from "@apollo/client";
import {
  SearchResult,
  SearchResultProfilesArgs,
  SearchResultAssetsArgs,
  SearchResultContractsArgs,
} from "utils/graphql/generated";

type SearchResultArgs =
  | SearchResultAssetsArgs
  | SearchResultProfilesArgs
  | SearchResultContractsArgs;

type SearchData = {
  search?: SearchResult;
};

type SearchQueryResponse = SearchData & {
  error?: ApolloError;
  loading: boolean;
};

const SearchGraphql = gql`
  query search($input: SearchInput!) {
    search {
      assets(input: $input) {
        id
        contract_address
        contract {
          contract_address
          name
          symbol
          schema
          name_custom
          image_url
          banner_image_url
        }
        token_id
        name
        description
        image_uri
        image_url_copy
        image_small_url_copy
        image_medium_url_copy
        image_blur_hash
        token_uri
        owners {
          profile {
            account_address
            username
            biography
            profile_image_url
            banner_image_url
          }
          quantity
        }
      }
      profiles(input: $input) {
        account_address
        username
        biography
        profile_image_url
        banner_image_url
      }
      contracts(input: $input) {
        contract_address
        name
        symbol
      }
    }
  }
`;

function useSearchQuery({
  filter,
  count,
  skip,
}: {
  filter: string;
  count: number;
  skip?: boolean;
}): SearchQueryResponse {
  const { data, error, loading } = useQuery<SearchData, SearchResultArgs>(
    SearchGraphql,
    {
      variables: {
        input: {
          filter: filter,
          count: count,
        },
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      skip: skip,
    }
  );

  const search = data?.search;
  return { search, error, loading };
}

export default useSearchQuery;
