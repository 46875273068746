import { colorType } from "utils/styles";
import * as paths from "utils/paths";

export const NAVIGATION: Array<{
  text: string;
  url: string;

  // color in the header
  color: colorType;

  // should display this navigation in the header
  displayInHeader: boolean;

  // should display this navigation in the footer
  displayInFooter: boolean;
}> = [
  {
    text: "Buy",
    url: paths.buy,
    color: "red",
    displayInHeader: true,
    displayInFooter: true,
  },
  {
    text: "Sell",
    url: paths.sell,
    color: "yellow",
    displayInHeader: true,
    displayInFooter: true,
  },
  {
    text: "Create",
    url: paths.create,
    color: "pink",
    displayInHeader: true,
    displayInFooter: true,
  },
];
