import React from "react";
import Link from "next/link";

import Socials from "components/modules/Socials";
import { NAVIGATION } from "components/common/Layout/Layout.utils";

function Footer() {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center font-sans font-medium"
          aria-label="Footer"
        >
          {NAVIGATION.map((item) => {
            if (!item.displayInFooter) {
              return null;
            }

            return (
              <div key={item.text} className="px-5 py-2">
                <Link href={item.url}>
                  <a className="text-base text-gray-500 hover:text-gray-900">
                    {item.text}
                  </a>
                </Link>
              </div>
            );
          })}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          <Socials />
        </div>
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; 2022 Diamond Paws Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
