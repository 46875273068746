import React from "react";
import Link from "next/link";
import Image from "next/image";

function Logo() {
  return (
    <Link passHref href="/">
      <a>
        <div className="w-24">
          <span className="sr-only">Aspect Logo</span>
          <div className="mt-2">
            <Image
              src="/img/company/logo-with-word-small.png"
              height={218}
              width={616}
              alt="logo"
            />
          </div>
        </div>
      </a>
    </Link>
  );
}

export default Logo;
