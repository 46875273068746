import { SearchIcon } from "@heroicons/react/outline";

function NotFound() {
  return (
    <div className="py-14 px-6 text-center text-sm sm:px-14">
      <SearchIcon
        className="mx-auto h-6 w-6 text-gray-400"
        aria-hidden="true"
      />
      <p className="mt-4 font-semibold text-gray-900">No results found</p>
    </div>
  );
}

export default NotFound;
