// this file should contain functions that help manage card tags for different social links
import React from "react";
import { isAbsoluteUrl } from "utils";
import urlJoin from "url-join";

const DEFAULT_IMAGE_URL =
  "https://testnet.aspect.co/img/company/website-bg.png";

// null values indicate that the default should be used
// if an empty string is specified, the tag is not set at all
export type CardTagsMeta = {
  title: string | null;
  description: string | null;
  imageUrl: string | null;
};

/**
 * if cardTagMeta is undefined, use defaults for all fields
 */
export function getCardTags(cardTagMeta?: CardTagsMeta) {
  if (cardTagMeta?.imageUrl && !isAbsoluteUrl(cardTagMeta.imageUrl)) {
    // HACK, for some meta cards, cannot use relative imports for packaged images
    cardTagMeta.imageUrl = urlJoin(
      "https://testnet.aspect.co",
      cardTagMeta.imageUrl
    );
  }

  return (
    <>
      {getTwitterCardTags(cardTagMeta)}
      {getDiscordCardTags(cardTagMeta)}
    </>
  );
}

function getTwitterCardTags(cardTagMeta?: CardTagsMeta): React.ReactNode {
  // DEVNOTE: use nullish coalesce operator here will set the value to an empty string if specified
  const twitterTitle = cardTagMeta?.title ?? "Aspect";
  const twitterDescription =
    cardTagMeta?.description ?? "Buy, Sell, and Create in the meta economy";
  const twitterImageUrl = cardTagMeta?.imageUrl ?? DEFAULT_IMAGE_URL;

  return (
    <>
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="aspect.co" />
      <meta property="twitter:url" content="https://testnet.aspect.co/" />
      <meta name="twitter:site" content="@aspectdotco"></meta>

      {cardTagMeta?.title !== "" && (
        <meta name="twitter:title" content={twitterTitle} />
      )}
      {cardTagMeta?.description !== "" && (
        <meta name="twitter:description" content={twitterDescription} />
      )}
      {cardTagMeta?.imageUrl !== "" && (
        <meta name="twitter:image" content={twitterImageUrl} />
      )}
    </>
  );
}

function getDiscordCardTags(cardTagMeta?: CardTagsMeta): React.ReactNode {
  // DEVNOTE: use nullish coalesce operator here will set the value to an empty string if specified
  const discordTitle = cardTagMeta?.title ?? "Aspect";
  const discordDescription =
    cardTagMeta?.description ??
    "Aspect is an NFT marketplace powered by StarkNet, a ZK rollup layer 2 on Ethereum. Buy, sell, and create in the meta economy.";
  const discordImageUrl = cardTagMeta?.imageUrl ?? DEFAULT_IMAGE_URL;

  return (
    <>
      <meta property="og:url" content="https://testnet.aspect.co/" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Aspect"></meta>

      {cardTagMeta?.title !== "" && (
        <meta property="og:title" content={discordTitle} />
      )}
      {cardTagMeta?.description !== "" && (
        <meta property="og:description" content={discordDescription} />
      )}
      {cardTagMeta?.imageUrl !== "" && (
        <meta property="og:image" content={discordImageUrl} />
      )}
    </>
  );
}
